/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, ColumnCover, ColumnDiv, Title, Subtitle, Text, SeparateLine, SeparateLineWrap, Fullmap, FullmapWrap } from '@swp/components'
import SiteHeader from '../../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Home"}>
        <SiteHeader set="cs" currentLanguage={0} />

        <Column className="pb--50 pt--80" name={"uvod"} layout={"l30"}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s5 --center el--1 --full pt--50 flex--center" anim={"7"} animS={"5"} columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5" anim={"2"} animS={"5"}>
              
              <Image style={{"maxWidth":670}} src={"https://cdn.swbpg.com/t/24903/da83d5c885c74ed6aebf785d9b563e72_s=860x_.png"} sizes="100vw" srcSet={"https://cdn.swbpg.com/t/24903/da83d5c885c74ed6aebf785d9b563e72_s=350x_.png 350w, https://cdn.swbpg.com/t/24903/da83d5c885c74ed6aebf785d9b563e72_s=660x_.png 660w, https://cdn.swbpg.com/t/24903/da83d5c885c74ed6aebf785d9b563e72_s=860x_.png 860w, https://cdn.swbpg.com/t/24903/da83d5c885c74ed6aebf785d9b563e72_s=1400x_.png 1400w, https://cdn.swbpg.com/t/24903/da83d5c885c74ed6aebf785d9b563e72_s=2000x_.png 2000w"} position={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="js-anim  --anim3 --anim-s5 --center pt--20 flex--top" anim={"3"} animS={"5"}>
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--185 w--300 lh--1" content={""}>
                    </Title>

                    <Subtitle className="subtitle-box fs--30 ls--004 lh--1 mt--0" content={"15 – 6 – 2024"}>
                    </Subtitle>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"text"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" style={{"maxWidth":1090}} anim={"2"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--20" content={"<span style=\"color: var(--color-blend--50);\">Rádi s Vámi oslavíme naši lásku.<br><br>14. 6. 2024 – v 16 hodin začínáme v Aria Hotel<br>15. 6. 2024 – svatební obřad v paláci Lobkowicz, hostina následuje ve Villa Richter <br></span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ubytovani"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48 swpf--uppercase" content={"<span style=\"color: rgb(205, 198, 182);\">ubytování</span>"}>
              </Title>

              <Title className="title-box fs--18" content={"<span style=\"color: var(--color-blend--50);\">14. 6. 2024\n</span>"}>
              </Title>

              <Text className="text-box ff--3 fs--18" style={{"maxWidth":399}} content={"<span style=\"color: var(--color-blend--50);\">Ubytování je zajištěno pro všechny mimopražské hosty na dvě noci v Aria Hotel nebo v hotelu Golden Key.<br><br>Check in bude umožněn od 15:00 hodin.<br>V 16:00 hodin proběhne welcome drink pro všechny ubytované i neubytované hosty.<br></span><a href=\"https://www.ariahotel.net/cs\" target=\"_blank\">ARIA HOTEL</a><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/24903/9665cdeb889843d3aed5fe4f7964906d_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/24903/9665cdeb889843d3aed5fe4f7964906d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/24903/9665cdeb889843d3aed5fe4f7964906d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/24903/9665cdeb889843d3aed5fe4f7964906d_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"uhrjpmltus"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: var(--color-blend--50);\">PÁTEČNÍ HARMONOGRAM</span>"}>
              </Title>

              <Text className="text-box ff--1 fs--20" style={{"maxWidth":838}} content={"15:00 Check-in v Aria Hotel a hotel Golden Key (v tomto hotelu jsou ubytováni pouze někteří hosté)<br>16:00&nbsp;Welcome drink v hotelu Aria pro všechny ubytované i neubytované hosty<br>&nbsp;17:00 Společný odchod na večerní akci<br><br>Doporučujeme pohodlnější obuv, protože se budeme pohybovat pěšky. <br><br>&nbsp;Hotel Golden Key (Nerudova ulice 27, Praha 1) je vzdálen 7 min chůze od hotelu Aria<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(241,239,234,1)"}} name={"svatebni-dary"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--72" content={"<span style=\"color: var(--color-blend--50);\">SVATEBNÍ DARY</span>"}>
              </Title>

              <Text className="text-box text-box--center ff--3 fs--20" style={{"maxWidth":650}} content={"Milí přátelé,\nmožná už přemýšlíte nad svatebním darem?&nbsp; Ze všeho nejvíce si přejeme být spolu šťastni, o to se ale postaráme my dva. &nbsp;Pak si přejeme, aby se na naší svatbě všichni dobře bavili, a o to se postaráme všichni společně.<br><br>&nbsp;Pokud byste nás i tak ještě chtěli něčím obdarovat, tak budeme velice rádi, <br>když se k nám připojíte a společně darujeme finanční příspěvek charitativní organizaci na záchranu opuštěných a týraných zvířat.&nbsp;<br><br>Číslo účtu, na které prosím zasílejte finanční příspěvky:<br>2302651932/2010<br><br>IBAN CZ5520100000002302651932<br>BIC&nbsp;FIOBCZPPXXX<br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"svatebni-den"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--72" content={"<span style=\"color: rgb(205, 198, 182);\">SVATEBNÍ DEN</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" style={{"paddingTop":0}} name={"16e7fw9kydy"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48 swpf--uppercase" content={"<span style=\"color: rgb(205, 198, 182);\">PALÁC&nbsp;Lobkowicz</span><br>"}>
              </Title>

              <Title className="title-box fs--18" content={"<span style=\"color: var(--color-blend--50);\">15. 6. 2024\n</span>"}>
              </Title>

              <Text className="text-box ff--3 fs--18" style={{"maxWidth":399}} content={"<span style=\"color: rgb(156, 147, 148);\">Svatební obřad proběhne v Císařském sále Lobkowiczkého paláce.&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":577}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/24903/fa3bebd901fa4542bc7ed5ae05d3e0c1_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/24903/fa3bebd901fa4542bc7ed5ae05d3e0c1_s=350x_.png 350w, https://cdn.swbpg.com/t/24903/fa3bebd901fa4542bc7ed5ae05d3e0c1_s=660x_.png 660w, https://cdn.swbpg.com/t/24903/fa3bebd901fa4542bc7ed5ae05d3e0c1_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"wq2y67ih6g"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":577}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/24903/0ad8d3c5bd174e348558a55000f874e6_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/24903/0ad8d3c5bd174e348558a55000f874e6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/24903/0ad8d3c5bd174e348558a55000f874e6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/24903/0ad8d3c5bd174e348558a55000f874e6_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--48" content={"<span style=\"color: rgb(205, 198, 182);\">VILLA RICHTER</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--18" content={"<span style=\"color: var(--color-blend--50);\">15. 6. 2024</span>"}>
              </Subtitle>

              <Text className="text-box ff--3 fs--18" style={{"maxWidth":399}} content={"<span style=\"color: var(--color-blend--50);\">Koktejlem ve Vinici zahájíme oslavu ve Villa Richter, poté bude následovat svatební hostina.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" style={{"marginTop":102}} name={"nx7w9hk1dfg"} layout={"l6"}>
          
          <SeparateLineWrap style={{"marginTop":0,"paddingLeft":0,"paddingRight":0,"paddingBottom":0,"backgroundColor":"rgba(205,198,182,1)"}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center pt--80" style={{"paddingBottom":0}} name={"cbqfq2ulyt9"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--48" content={"<span style=\"color: rgb(205, 198, 182);\">DOPRAVA</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":31,"paddingBottom":0}} name={"doprava"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--20" content={"Doprava je zajištěna z hotelu Aria minibusy pro všechny ubytované hosty.<br><br>Neubytovaní hosté se do Lobkowiczkého paláce dopraví sami, pěšky po starých zámeckých schodech.<span style=\"color: var(--color-blend--50);\"><br><br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pt--10" style={{"marginBottom":88,"paddingBottom":52}} name={"n4sdb6ufz2n"}>
        </SeparateLine>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(241,239,234,1)"}} name={"harmonogram"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48" content={"<span style=\"color: var(--color-blend--50);\">HARMONOGRAM SVATEBNÍHO DNE</span>"}>
              </Title>

              <Text className="text-box text-box--center ff--3 fs--20 pl--40 pr--40" style={{"maxWidth":900,"marginBottom":0}} content={"11:45 Ubytovaní hosté odjíždí z hotelu Aria minibusy.<br>Prosím buďte na místě s předstihem&nbsp;<br><br>12:00 Welcome drink v Lobkowiczkém paláci<br>13:00 Svatební obřad v Císařském sále<br>13:30 Gratulace, přípitek<br>14:30 Svatební koktejl ve Vinici Villa Richter<br>16:00 Svatební hostina ve Villa Richter<br>19:00 První tanec<br>20:00 Zakrojení dortu<br>22:00 Svatební party ve Skleníku<br>22:00 Večerní občerstvení<br>02:00 Konec oslavy&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"bzidc11xwyg"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="--center pt--80" style={{"marginTop":0,"paddingBottom":0}} name={"f88ruqlrv1t"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--72" content={"<span style=\"color: rgb(205, 198, 182);\">DRESS CODE</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"dress-code"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box fs--36" content={"<span style=\"color: var(--color-custom-1);\">1. DEN</span>"}>
              </Title>

              <Text className="text-box ff--3" content={"<span style=\"color: var(--color-blend--50);\">SMART CASUAL<br></span>Tento den vás rádi přivítáme v bílo-černé barvě nebo zvolte jednu z těchto barev. Styl je smart casual, hlavně abyste se cítili pohodlně.<br>"}>
              </Text>

              <Image style={{"maxWidth":242}} alt={""} src={"https://cdn.swbpg.com/t/24903/cd65a835b23140288cb09b4845540afc_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/24903/cd65a835b23140288cb09b4845540afc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/24903/cd65a835b23140288cb09b4845540afc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/24903/cd65a835b23140288cb09b4845540afc_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box fs--36" content={"<span style=\"color: var(--color-custom-1);\">2. DEN</span>"}>
              </Title>

              <Text className="text-box ff--3" content={"<span style=\"color: var(--color-blend--50);\">COCKTAIL CHIC<br></span>Ne bílá a ne černá, zvolte cocktail chic. V bílé by měla být pouze nevěsta, v černé zase ženich, ale finální výběr necháme na vás.\nTerén Villy Richter je kostkovitý, proto prosíme pozor, pokud si budete chtít vzít vysoké podpatky. Doporučujeme obuv na přezutí.&nbsp;&nbsp;<br>"}>
              </Text>

              <Image style={{"maxWidth":242}} alt={""} src={"https://cdn.swbpg.com/t/24903/cd65a835b23140288cb09b4845540afc_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/24903/cd65a835b23140288cb09b4845540afc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/24903/cd65a835b23140288cb09b4845540afc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/24903/cd65a835b23140288cb09b4845540afc_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"galerie"} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/24903/93c738bc84f44c2fb110283fef01187f_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/24903/93c738bc84f44c2fb110283fef01187f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/24903/93c738bc84f44c2fb110283fef01187f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/24903/93c738bc84f44c2fb110283fef01187f_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/24903/22d72bce6dde4bcf95b6efa12c3a54e0_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/24903/22d72bce6dde4bcf95b6efa12c3a54e0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/24903/22d72bce6dde4bcf95b6efa12c3a54e0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/24903/22d72bce6dde4bcf95b6efa12c3a54e0_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"marginTop":0,"paddingTop":40,"paddingBottom":0}} name={"kontakt"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--72" content={"<span style=\"color: rgb(205, 198, 182);\">KONTAKT</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--80" style={{"paddingBottom":54}} name={"id2kojr5bai"} layout={"l1"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--30" content={"<span style=\"color: var(--color-custom-1);\">ARIA HOTEL</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box ff--2 lh--2" style={{"maxWidth":270}} content={"<span style=\"color: var(--color-blend--50);\">Tržiště 9<br>118 00 Praha - Malá Strana<br>+420 225 334 111</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"qnq4w1pezj"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingBottom":54,"paddingTop":55}} name={"id2kojr5bai"} layout={"l1"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--30" content={"<span style=\"color: var(--color-custom-1);\">GOLDEN KEY</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box ff--2 lh--2" style={{"maxWidth":270}} content={"<span style=\"color: var(--color-blend--50);\">Nerudova ulice 27<br>110 00 Praha - Malá Strana<br>+420 257 317 999</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" style={{"marginTop":0}} name={"04r7qnyxso2c"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":44,"paddingBottom":48}} name={"id2kojr5bai"} layout={"l1"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--30" content={"<span style=\"color: rgb(235, 232, 225);\">PALÁC LOBKOWICZ</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box ff--2 lh--2" style={{"maxWidth":270}} content={"<span style=\"color: var(--color-blend--50);\">Jiřská 3<br>119 00 Praha 1&nbsp;- Malá Strana<br>+420 702 201 145</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"qnq4w1pezj"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--80" style={{"paddingTop":44}} name={"id2kojr5bai"} layout={"l1"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--30" content={"<span style=\"color: var(--color-custom-1);\">VILLA RICHTER</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box ff--2 lh--2" style={{"maxWidth":270}} content={"<span style=\"color: var(--color-blend--50);\">Staré zámecké schody 6/251&nbsp;<br>118 00</span>&nbsp;<span style=\"color: var(--color-blend--50);\">Praha - Hradčany<br>+420 702 282 402</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"d594slw9agm"}>
          
          <FullmapWrap className="pb--20 pt--20">
            
            <Subtitle className="subtitle-box subtitle-box--center fs--72" content={"<span style=\"color: rgb(205, 198, 182);\">MAPA</span>"}>
            </Subtitle>

          </FullmapWrap>

          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Fullmap className="--style2" name={"3571u7dcfwz"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="pb--60 pt--60" layout={"l9"} name={"wlpt16k8nrd"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image style={{"maxWidth":1000}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/24903/5546fb141fc749aeb6f7d4fb43e3dc7e.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"m73ni9vwxl8"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center" style={{"paddingTop":23,"paddingBottom":0}} name={"5yjhc6243fv"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box ff--3 fs--20" style={{"maxWidth":654}} content={"<span style=\"color: var(--color-blend--50);\">V případě dalších dotazů se prosím obraťte na naši svatební koordinátorku.\n<br>Eva: eva@ifconcept.cz\n</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"marginTop":0,"paddingTop":65,"marginBottom":0,"paddingBottom":0}} name={"nqjp20guna"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":1244}} alt={""} src={"https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=860x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=350x_.png 350w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=660x_.png 660w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=860x_.png 860w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=1400x_.png 1400w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80" style={{"paddingTop":0}} name={"paticka-2"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1090}} anim={null} animS={null} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--36 mt--10" content={"Těšíme se na Vás!"}>
              </Title>

              <Text className="text-box text-box--center mt--10" content={"<a href=\"/galerie\">Petra &amp; Petr</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}